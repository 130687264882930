import React from "react";
import "../Action/Action.css"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

export default function Action(){

    return(
        <div>
            <Navbar/>
        <div className="container seeaction" id="action">
            <div className="action">
            <div>
                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non. Accumsan lacus vel facilisis volutpat. Sed arcu non odio euismod lacinia at. Aliquam sem et tortor consequat id porta nibh venenatis. Ut eu sem integer vitae justo eget magna. Fermentum odio eu feugiat pretium nibh ipsum consequat. Euismod nisi porta lorem mollis aliquam ut porttitor leo a. Vivamus at augue eget arcu dictum varius duis at consectetur. Eu sem integer vitae justo eget magna. Faucibus et molestie ac feugiat sed lectus vestibulum mattis. Dictum fusce ut placerat orci nulla pellentesque dignissim enim. Adipiscing elit pellentesque habitant morbi tristique senectus et. Mauris augue neque gravida in. Ultricies integer quis auctor elit sed vulputate mi. Nibh praesent tristique magna sit amet purus.</p>
            </div>
            <div className="d-flex">
                <div className="rectangle one"></div>
                <div className="rectangle two"></div>
            </div>
            </div>
            </div>  
        <div className="action-footer"></div> 
        </div>
    )
}