import React from "react"
import Navbar from "../Navbar/Navbar"
import img6 from "../images/homebg.jpg";
import "./Onboarding.css";
import axios from "axios"
import { useNavigate } from "react-router-dom";

export default function Onboarding(){
    const [onboardingData, setOnboardingData] = React.useState({
        name:'',
        email:'',
        refered:false,
        university:"Select a university",
        degree:'Select a degree',
        category:'Select a category',
        gender:'Select your gender',
        ethnicity:'Select your ethnicity',
        parentDeg:false
    })
    const navigate=useNavigate()
    function handleChange(event){
        const {name,value} = event.target;
        setOnboardingData(prevState =>{
            return {
                ...prevState,
                [name]:value
            }
        })
    }

    const submitOnboard = async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        try{
            const config = {
                headers:{
                    "Content-type":"application/json",
                },
            };
            const response = await axios.post("http://localhost:8000/api/user/onboard",{onboardingData},config);
            if(onboardingData.refered===true){
                navigate("/lackingwellness");
            }
            else{
                navigate("/supportgroups/financial")
            }
        }
        catch(error){
            console.log(error)
        }

    }
    return(
        <div>
            <div><Navbar/></div>
            <div>
            <img className="onboarding-img" src={img6}/>
            <div className=" onboarding">
                <h4 className=""> Student Onboarding Questionnaire</h4>
                <div className="text-start">
                    <div className="d-flex flex-row justify-content-between my-3 ">
                        <div><label>Name</label></div>
                            <div><input name = 'name' value={onboardingData.name} onChange={handleChange}></input></div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 ">
                        <div><label>Email</label></div>
                            <div><input name = 'email' value={onboardingData.email} onChange={handleChange}></input></div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3">
                        <div><label className="">Were you referred by your Campus Resource Center?</label></div>
                        <div className="d-flex">
                        <div onClick ={()=> setOnboardingData(prevState => ({...prevState,refered:true}))} className={`xx mx-4 ${onboardingData.refered ? 'bg-yellow':''}`}>Yes</div>
                                <div onClick={() => setOnboardingData(prevState => ({ ...prevState, refered: false }))} className={`xx ${!onboardingData.refered ? 'bg-yellow' : ''}`}>No</div>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3">
                        <div><label>University</label></div>
                        <div>
                                <select name='university' value={onboardingData.university} onChange={handleChange}>
                                    <option disabled selected>Select a university</option>
                                    <option>Indian Institute of Science (IISc), Bangalore</option>
                                    <option>Jawaharlal Nehru University (JNU), New Delhi</option>
                                    <option>Jadavpur University, Kolkata</option>
                                    <option>University of Hyderabad (UoH), Hyderabad</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3">
                        <div><label>Which degree are you currently pursuing?</label></div>
                        <div>
                                <select name='degree' value={onboardingData.degree} onChange={handleChange}>
                                    <option disabled selected>Select a degree</option>
                                    <option>Bachelor's Degrees</option>
                                    <option>Doctoral Degrees</option>
                                    <option>Health Sciences Degrees</option>
                                    <option>Fine Arts Degrees</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3">
                        <div><label>Student Category</label></div>
                        <div>
                                <select name='category' value={onboardingData.category} onChange={handleChange}>
                                    <option disabled selected>Select a category</option>
                            <option>International Student</option>
                                    <option>National Student</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row  justify-content-between my-3">
                        <div><label>Gender</label></div>
                        <div>
                                <select name='gender' value={onboardingData.gender} onChange={handleChange}>
                                    <option disabled selected>Select your gender</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3">
                        <div><label>Ethnicity</label></div>
                        <div>
                                <select name='ethnicity' value={onboardingData.ethnicity} onChange={handleChange}>
                            <option disabled selected>Select your ethnicity</option>
                                    <option>Han Chinese</option>
                                    <option>Indo-Aryan</option>
                                    <option>Arab</option>
                                    <option>African</option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3">
                        <div><label>Do either of your parents hold a 4-year degree?</label></div>
                        
                        <div className="d-flex">
                                <div onClick={() => setOnboardingData(prevState => ({ ...prevState, parentDeg: true }))} className={`xx mx-4 ${onboardingData.parentDeg ? 'bg-yellow' : ''}`}>Yes</div>
                                <div onClick={() => setOnboardingData(prevState => ({ ...prevState, parentDeg: false }))} className={`xx  ${!onboardingData.parentDeg ? 'bg-yellow' : ''}`}>No</div>
                        </div>
                        
                    </div>
                </div>
                <button onClick={(e)=>submitOnboard(e)} className="btn">Submit</button>

            </div>
            </div>
            <div></div>
        </div>
    )
}