import React from "react";
import img3 from "../images/logo.png"
import { Link } from "react-router-dom"; 

import "./CRCnavbar.css"

export default function CRCnavbar(){
    const[toggledrop,setToggleDrop] = React.useState(false);
    return(
        <div className="crc-nav-bar">
            <nav className="row">
                <div className="col-md-6 text-start"><img src={img3}/></div>
                <div className="col-md-6 ">
                    <ul className="row fs-5 d-flex">
                        <a className=" col anchor dropdown-navbar" >
                        <span onClick = {()=> setToggleDrop(prevState => !prevState)}>Descriptive Analytics</span>
                           { toggledrop &&  <div className="dropdown-content-navbar ">
                                <a href="https://app.powerbi.com/view?r=eyJrIjoiM2ZhMGRjMzktOGFjMy00OTcyLWIyNjAtYjhlMWU4ZmE3ZTcyIiwidCI6IjQ5ZjBiNjU4LWE2NmItNDMyZC05NzEzLTZhZmRhNzNiM2ZlYSIsImMiOjZ9">Q1</a> 
                                <a href="https://app.powerbi.com/view?r=eyJrIjoiM2ZhMGRjMzktOGFjMy00OTcyLWIyNjAtYjhlMWU4ZmE3ZTcyIiwidCI6IjQ5ZjBiNjU4LWE2NmItNDMyZC05NzEzLTZhZmRhNzNiM2ZlYSIsImMiOjZ9">Q2</a> 
                                <a href="https://app.powerbi.com/view?r=eyJrIjoiM2ZhMGRjMzktOGFjMy00OTcyLWIyNjAtYjhlMWU4ZmE3ZTcyIiwidCI6IjQ5ZjBiNjU4LWE2NmItNDMyZC05NzEzLTZhZmRhNzNiM2ZlYSIsImMiOjZ9">Q3</a> 
                                <a href="https://app.powerbi.com/view?r=eyJrIjoiM2ZhMGRjMzktOGFjMy00OTcyLWIyNjAtYjhlMWU4ZmE3ZTcyIiwidCI6IjQ5ZjBiNjU4LWE2NmItNDMyZC05NzEzLTZhZmRhNzNiM2ZlYSIsImMiOjZ9">Q4</a> 
                            </div>}
                        </a>
                        <a className="col anchor" href="https://app.powerbi.com/view?r=eyJrIjoiYjFlMGVkZDYtNDk5ZC00MzdjLWIwZDQtMzllYzk1MGMzNjgwIiwidCI6IjQ5ZjBiNjU4LWE2NmItNDMyZC05NzEzLTZhZmRhNzNiM2ZlYSIsImMiOjZ9">Predictive analytics</a>
                        <Link to="/reachout" className="col"><a className=" anchor" >Contact Us</a></Link>
                        <Link to="" className="col"><a className=" anchor" >Logout</a></Link>
                        
                        
                    </ul>
                </div>
            </nav>
        </div>
    )
}