import React from "react";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import Sidepanel from "../Sidepanel/Sidepanel";
import group49 from "../../images/Group 49.png"
import sos from "../../images/sos.png";

export default function IndSpiritual(){
    return(
        <div>
            <div><StudentrefNavbar/></div>
            <div className="row main-wellness">
                <div className="col-2"><Sidepanel/></div>
                <div className=" col-10 ">
                <div className="fw-bold my-2">Spiritual</div>
                <div className="main-wellness-chat">
                    <div className = "spiritual-chat-ai">
                        <div className="spiritual-chat-img">
                            <img src={group49}/>
                        </div>
                        <div className="spiritual-chat-msg client">
                        </div>
                    </div>
                    <div className = "spiritual-chat-user">
                        <div className="spiritual-chat-msg user">
                        </div>
                        <div className="spiritual-chat-img">
                            <img src={sos}/>
                        </div>
                        
                    </div>
                    <div className = "spiritual-chat-ai">
                        <div className="spiritual-chat-img">
                            <img src={group49}/>
                        </div>
                        <div className="spiritual-chat-msg client">
                        </div>
                    </div>
                    <div className = "spiritual-chat-input">
                        <div className="spiritual-input-box">
                        <input type="spiritual-text"></input>
                        <button><i class='bx bx-up-arrow-alt'></i></button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div>
                <div className="floating-support">
                    <h5 className="fw-bolder">Here are the support resources and groups to help you navigate your challenges</h5>
                    <div>
                    <button className="btn login-button ">Financial</button>
                    <button className="btn login-button ">Physical</button>
                    </div>
                    <button className="x-mark cross"><i class='bx bxs-x-circle'></i></button>
                </div>
            </div>
        </div>
    )
}