import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Reachout from './components/Reachout/Reachout';
import Action from './components/Action/Action';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './components/Login/Login';
import Studentlogin from './components/Login/Studentlogin';
import CrcDashboard from './components/Crc/CrcDashboard';
import Crcform from './components/Crc/Crcform';
import Crcsumit from "./components/Crc/Crcsumit"
import Studentverify from './components/Studentverify/Studentverify';
import Wellnessexpert from './components/StudentrefPages/Wellnesexperts/Wellnessexpert';
import Financial from './components/StudentrefPages/Supportgroups/Financial';
import Verifysuccessful from './components/Studentverify/Verifysuccessful';
import VerifyUnsuccessful from './components/Studentverify/VerifyUnsuccessful';
import Onboarding from './components/Onboarding/Onboarding';
import Footer from './components/Footer/Footer';
import Independentpage from './components/StudentIndpages/Independentpage/Independentpage';
import Lackingwellness from './components/StudentIndpages/Lackingwellness/Lackingwellness';
import StudentProfile from './components/StudentProfile/StudentProfile';
import Studentedit from './components/StudentProfile/Studentedit';
import Sidepanel from './components/StudentrefPages/Sidepanel/Sidepanel';
import Spiritual from './components/StudentrefPages/Wellnesspages/Spiritual';
import IndSpiritual from './components/StudentIndpages/Wellnesspages/IndSpiritual';


function App() {
  return (
    <BrowserRouter>
    <Routes>
     
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/reachout' element={<Reachout/>}/>
      <Route path='/action' element={<Action />}/>
      <Route path='/CRClogin' element={<Login />}/>
      <Route path='/Studentlogin' element={<Studentlogin />}/>
      <Route path='/crcdashboard' element={<CrcDashboard />}/>
      <Route path='/crcform' element={<Crcform/>}></Route>
      <Route path='/crcsumit' element={<Crcsumit/>}></Route>
      <Route path='/studentref' element={<Wellnessexpert/>}></Route>
      <Route path='/supportgroups/financial' element={<Financial/>}></Route>
      <Route path='/lackingwellness' element={<Lackingwellness/>}></Route>
      <Route path='/studentverify' element={<Studentverify/>}></Route>
      <Route path='/success' element={<Verifysuccessful/>}></Route>
      <Route path='/unsuccess' element={<VerifyUnsuccessful/>}></Route>
      <Route path='/onboarding' element={<Onboarding/>}></Route>
      <Route path='/independent' element={<Independentpage/>}></Route>
      <Route path='/profile' element={<StudentProfile/>}></Route>
      <Route path='/profile/edit' element={<Studentedit/>}></Route>
      <Route path='/studentref/spiritual' element={<Spiritual/>}></Route>
      <Route path='/independent/spiritual' element={<IndSpiritual/>}></Route>
    </Routes>
    
    </BrowserRouter>
    
  );
}

export default App;
