import React from 'react';
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import "./Wellnessexpert.css"
import group40 from "../../images/Group 40.png";
import group41 from "../../images/Group 41.png";
import group47 from "../../images/Group 47.png";
import group45 from "../../images/Group 45.png";
import group42 from "../../images/Group 42.png";
import group39 from "../../images/Group 39.png";
import group43 from "../../images/Group 43.png";
import group44 from "../../images/Group 44.png";
import group46 from "../../images/Group 46.png";
import Footer from "../../Footer/Footer";

export default function Wellnessexpert() {
    return (
        <div className="studentref">
            <StudentrefNavbar />
            <div className="wellness-main">
                <div className="wellness-head">9 Wellness Experts</div>
                <div className='wellness-progress d-flex align-items-center gap-4 ms-4 me-4'>
                    <div>Your Progress</div>
                    <div class="progress" style={{ "width": "60rem","height":"1.5rem" }}role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        <div class="progress-bar" style={{ "width": "35%", "background-color":" #FFD800","color":"black" }}>35%</div>
                    </div>
                </div>
                <div className="wellness-items-1">
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group40} />
                            <div className="item-progress">30%</div>
                        </div>
                            <div>Career</div>
                    </div>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group41} />
                            <div className="item-progress">0%</div>
                            </div>
                            <div>Creative</div>
                    </div>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group47} />
                            <div className="item-progress">0%</div>
                            </div>
                            <div>Emotional</div>
                    </div>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group45} />
                            <div className="item-progress">30%</div>
                            </div>
                            <div>Environmental</div>
                    </div>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group42} />
                            <div className="item-progress">0%</div>
                            </div>
                            <div>Intellectual</div>
                    </div>
                    
                </div>
                <div className="wellness-items-2">
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group39} />
                            <div className="item-progress">30%</div>
                        </div>
                            <div>Career</div>
                    </div>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group43} />
                            <div className="item-progress">0%</div>
                            </div>
                            <div>Creative</div>
                    </div>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group44} />
                            <div className="item-progress">0%</div>
                            </div>
                            <div>Emotional</div>
                    </div>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group46} />
                            <div className="item-progress">30%</div>
                            </div>
                            <div>Environmental</div>
                    </div>
                    
                </div>
            </div>

            {/* <Footer/> */}
        </div>
    )
}


