import React from 'react';
import "./Financial.css";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";

export default function Financial() {
    return (
        <div>
            <StudentrefNavbar />
            <div className="support-group-main">
                <div className="support-group-sidebar">
                    <div className="group-item">Spiritual</div>
                    <div className="group-item">Social</div>
                    <div className="group-item">Career</div>
                    <div className="group-item">Creative</div>
                    <div className="group-item">Emotional</div>
                    <div className="group-item">Environmental</div>
                    <div className="group-item">Intellectual</div>
                    <div className="group-item group-active">Financial</div>
                    <div className="group-item">Physical</div>
                </div>
                <div className="support-group-body">
                    <div className="group-body-head ">Financial</div>
                    <div className="group-content">
                        <div className="group-content-para grid-col-span-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non. Accumsan lacus vel facilisis volutpat. Sed arcu non odio euismod lacinia at. Aliquam sem et tortor consequat id porta nibh venenatis. Ut eu sem integer vitae justo eget magna. Fermentum odio eu feugiat pretium nibh ipsum consequat. Euismod nisi porta lorem mollis aliquam ut porttitor leo a. Vivamus at augue eget arcu dictum varius duis at consectetur. Eu sem integer vitae justo eget magna. Faucibus et molestie ac feugiat sed lectus vestibulum mattis. Dictum fusce ut placerat orci nulla pellentesque dignissim enim. Adipiscing elit pellentesque habitant morbi tristique senectus et. Mauris augue neque gravida in. Ultricies integer quis auctor elit sed vulputate mi. Nibh praesent tristique magna sit amet purus.</div>
                        <div className="group-content-para">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non. Accumsan lacus vel facilisis volutpat. Sed arcu non odio euismod lacinia at. Aliquam sem et tortor consequat id porta nibh venenatis. Ut eu sem integer vitae justo eget magna. Fermentum odio eu feugiat pretium nibh ipsum consequat. Euismod nisi porta lorem mollis aliquam ut porttitor leo a. Vivamus at augue eget arcu dictum varius duis at consectetur. Eu sem integer vitae justo eget magna. Faucibus et molestie ac feugiat sed lectus vestibulum mattis. Dictum fusce ut placerat orci nulla pellentesque dignissim enim. Adipiscing elit pellentesque habitant morbi tristique senectus et. Mauris augue neque gravida in. Ultricies integer quis auctor elit sed vulputate mi. Nibh praesent tristique magna sit amet purus.
                        </div>
                        <div className='group-content-boxs'>
                            <div className='group-content-box'></div>
                            <div className='group-content-box'></div>
                        </div>
                    </div>
                    <div className="group-body-head ">Community Platform</div>
                    <div className="community-platform">
                        <div className="community-comments">
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Jane</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>2 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>
                            <div className="community-reply-box">
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Jhon</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                1 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                                <div className="community-comment-box">
                                    <div className="comment-head">
                                        <div className="comment-name " >Sam</div>
                                        <div className="comment-time-head" >
                                            <div className="comment-time" >
                                                2 hour ago
                                            </div>
                                            <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                        </div>
                                    </div>
                                    <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</div>
                                </div>
                            </div>
                            <div className="community-comment-box">
                                <div className="comment-head">
                                    <div className="comment-name " >Ben</div>
                                    <div className="comment-time-head" >
                                        <div className="comment-time" >
                                            3 hours ago
                                        </div>
                                        <div><i class='bx bx-dots-vertical-rounded'></i></div>
                                    </div>
                                </div>
                                <div className="comment-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morbi leo urna molestie at elementum eu. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non.</div>
                                <div className="comment-foot">
                                    <div>5 Comments</div>
                                    <div>Reply</div>
                                </div>
                            </div>

                        </div>
                        <div className="community-chat-input">
                            <div className="input-box">
                                <input type="text" placeholder="Start a post..."></input>
                                <button><i class='bx bx-up-arrow-alt'></i></button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}
