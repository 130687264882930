import React from "react";
import "../Navbar/Navbar.css"
import img3 from "../images/logo.png"
import { Link } from "react-router-dom"; 

export default function Navbar(){
    return(
        <div className="nav-bar">
            <nav className="row">
                <div className="col-md-6 text-start"><img src={img3}/></div>
                <div className="col-md-6 ">
                    <ul className="row fs-5 d-flex">
                        <Link to="/" className="col"><a className=" anchor" >Home</a></Link>
                        <Link to="/about" className="col"><a className=" anchor" >About</a></Link>
                        <Link to="/reachout" className="col"><a className=" anchor" >Reach out?</a></Link>
                        <Link to="/action" className="col"><a className=" anchor" >See it in Action</a></Link>
                        <Link className="col"><a className="dropdown ">
                            <span>Login</span>
                            <div className="dropdown-content ">
                                <Link to="/Studentlogin"><p>Student</p></Link>
                                <Link to="/CRClogin"><p>Resource center</p></Link>
                            </div>
                        </a></Link>
                        
                    </ul>
                </div>
            </nav>
        </div>
    )
}