import React from "react";
import "./Independentpage.css";
import group49 from "../../images/Group 49.png";
import sos from "../../images/sos.png";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";

export default function Independentpage(){

    
    return(
        <div>
            <div><StudentrefNavbar/></div>
            <div className="independentpage">
                <div className="fw-bold">Central AI Agent</div>
                <div className="independent-chat">
                    <div className = "chat-ai">
                        <div className="chat-img">
                            <img src={group49}/>
                        </div>
                        <div className="chat-msg">
                        </div>
                    </div>
                    <div className = "chat-user">
                        <div className="chat-msg">
                        </div>
                        <div className="chat-img">
                            <img src={sos}/>
                        </div>
                        
                    </div>
                    <div className = "chat-ai">
                        <div className="chat-img">
                            <img src={group49}/>
                        </div>
                        <div className="chat-msg">
                        </div>
                    </div>
                    <div className = "chat-input">
                        <div className="input-box">
                        <input type="text"></input>
                        <button><i class='bx bx-up-arrow-alt'></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}