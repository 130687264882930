import React from 'react';
import "../Team/Team.css";

export default function Team(){
    return(
        <div className='container'>
            
            <div className='team-container'>
            <p className="team text-start">Meet the Core Team</p>
            <div className='row'>
            <div className='col-3 team-content'>
                <div className='square'><img src=""/></div>
                <h4>Avantika Singh</h4>
                <p>Cheif Executive Officer Ai Chip Design,EECS</p>
            </div>
            <div className='col-3 team-content'>
                <div className='square'><img src=""/></div>
                <h4>Neil Nagraj</h4>
                <p>Chief Technology Officer AI, Computer Science</p>
            </div>
            <div className='col-3 team-content'>
            <div className='square'><img src=""/></div>
                <h4>Jaideep Rao Allad</h4>
                <p>Chief Operating Officer Mechanical & Aerospace Engineering</p>
            </div>
            <div className='col-3 team-content'>
            <div className='square'><img src=""/></div>
                <h4>Himanshu Singh</h4>
                <p>Chief Financial Officer</p>
            </div>
            </div>
            </div>
            
        </div>
    )
}