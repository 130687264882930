import React from "react";

export default function Sidepanel(){
    return(
        <div>
            <div className="sidepanel ">
                <h6 className="text-start fw-bolder my-2">Lacking</h6>
                <div className="sidepanel-box ">
                    <div>50%</div>
                    <p>Spiritual</p>
                </div>
                <div className="sidepanel-box ">
                <div>10%</div>
                    <p>Social</p>
                </div>
                <h6 className="text-start fw-bolder my-2">Other</h6>
                <div className="sidepanel-box"> 
                <div>0%</div>
                    <p>Career</p>
                </div>
                <div className="sidepanel-box"><div>0%</div>
                    <p>Creative</p></div>
                <div className="sidepanel-box"><div>0%</div>
                    <p>Emotianal</p></div>
                <div className="sidepanel-box"><div>0%</div>
                    <p>Environmental</p></div>
                <div className="sidepanel-box"><div>0%</div>
                    <p>Intellectual</p></div>
                <div className="sidepanel-box"><div>0%</div>
                    <p>Financial</p></div>
                <div className="sidepanel-box"><div>0%</div>
                    <p>Physical</p></div>
            </div>
        </div>
    )
}