import React from "react"
import StudentrefNavbar from "../StudentrefNavbar/StudentrefNavbar";
import "./StudentProfile.css"
import img8 from "../images/sos.png"

export default function Studentedit(){
    return(
        <div>
            <div><StudentrefNavbar/></div>
            <div className="student-profile">
                <div className="student-box d-flex container">
                    <div className="profilediv ">
                    <div><img className="profile-img " src={img8}/></div>
                    <div><button className="btn edit-btn">Update</button></div>
                    </div>
                    <div className=" vl"></div>
                    <div className="text-start fs-6 submain fw-bolder">
                    <div className="d-flex flex-row justify-content-between my-3  main">
                        <div><label >Name</label></div>
                        <div><input></input></div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 main">
                        <div><label>Email</label></div>
                        <div><input></input></div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 main">
                        <div><label>University</label></div>
                        <div>
                        <select>
                            <option></option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 main">
                        <div><label>Degree</label></div>
                        <div>
                        <select>
                            <option></option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 main">
                        <div><label>Student Category</label></div>
                        <div>
                        <select>
                            <option></option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row  justify-content-between my-3 main">
                        <div><label>Gender</label></div>
                        <div>
                        <select>
                            <option></option>
                            <option></option>
                        </select>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row justify-content-between my-3 main">
                        <div><label>Ethnicity</label></div>
                        <div>
                        <select>
                            <option></option>
                            <option></option>
                        </select>
                        </div>
                        
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}