 import React from "react";
import "./Lackingwellness.css";
import group49 from "../../images/Group 49.png";
import sos from "../../images/sos.png";
import StudentrefNavbar from "../../StudentrefNavbar/StudentrefNavbar";
import group44 from "../../images/Group 44.png";
import group46 from "../../images/Group 46.png";
export default function Lackingwellness(){
    return(
        <div>
            <div><StudentrefNavbar/></div>
            <div className="lackingwellness-main">
                <div className = 'lackingwellnesspage'>
                <div className="fw-bold">Central AI Agent</div>
                <div className="lackingwellness-chat">
                    <div className = "chat-ai">
                        <div className="chat-img">
                            <img src={group49}/>
                        </div>
                        <div className="chat-msg">
                        </div>
                    </div>
                    <div className = "chat-user">
                        <div className="chat-msg">
                        </div>
                        <div className="chat-img">
                            <img src={sos}/>
                        </div>
                        
                    </div>
                    <div className = "chat-ai">
                        <div className="chat-img">
                            <img src={group49}/>
                        </div>
                        <div className="chat-msg">
                        </div>
                    </div>
                    <div className = "chat-input">
                        <div className="input-box">
                        <input type="text"></input>
                        <button><i class='bx bx-up-arrow-alt'></i></button>
                        </div>
                    </div>
                </div>
                </div>
                <div className="lackingwellness-box">
                    <div className="lackingwellness-box-1">
                        <div className="box-1-head">
                            Chat with the below Wellness Experts
                        </div>
                        <div className="box-1-imgs">
                            <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group44} />
                            <div className="item-progress">30%</div>
                        </div>
                            <div>Spritual</div>
                    </div>
                    <div className='wellness-item'>
                        <div className="item-img">
                            <img src={group46} />
                            <div className="item-progress">30%</div>
                        </div>
                            <div>Social</div>
                    </div>
                    <button className="x-mark"><i class='bx bxs-x-circle'></i></button>
                        </div>
                </div>
                </div>
            </div>
        </div>
    )
}