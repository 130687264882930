import React from "react";
import "../StudentrefNavbar/StudentrefNavbar.css"
import img3 from "../images/logo.png"
import { Link } from "react-router-dom"; 

export default function StudentrefNavbar(){
    return(
        <div className="nav-bar">
            <nav className="row">
                <div className="col-md-6 text-start"><img src={img3}/></div>
                <div className="col-md-6 ">
                    <ul className="row fs-6 d-flex">
                        <Link to="/" className="col"><a className=" anchor" >Wellness Expert</a></Link>
                        <Link to="/" className="col"><a className=" anchor" >Support Groups</a></Link>
                        <Link to="/" className="col"><a className=" anchor" >Your Report</a></Link>
                        <Link to="/" className="col"><a className=" anchor" >Profile</a></Link>
                        <Link to="/" className="col"><a className=" anchor" >Logout</a></Link>
                        
                    </ul>
                </div>
            </nav>
        </div>
    )
}