import React from "react";
import "../About/About.css"
import Team from "../Team/Team";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";


export default function About(){
    return(
        <div id="about">
            <Navbar/>
        <div className="about-cont">
            <p>Our Journey </p>
            <div className="yellow1 rect"></div>
            <div className="line1"></div>
            <div className="d-blue1 rect"></div>
            <div className="line2"></div>
            <div className="blue1 rect"></div>
            <div className="line3"></div>
            <div className="yellow2 rect"></div>
            <div className="line4"></div>
            <div className="d-blue2 rect"></div>
            <div className="line5"></div>
            <div className="blue2 rect"></div>
        </div>
        <Team/>
        
        </div>
        
    )
}