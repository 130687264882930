import React from "react";
import "../Home/Home.css"
import img1 from "../images/home.jpg";
import img2 from "../images/vector.png"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import img3 from "../images/campus-student-image.png"

export default function Home(){
    return(
        <div >
          <Navbar/>
        <div className="row">
            <div className="col-8 home-div1"><img  src={img1}/></div>
            <div className="home-div2 col-4 ">
                <img src={img2}/>
                <p className="text-end">Meet our <span className="fw-bolder">Generative AI</span> wellness companion, enhancing student experience with <span className="fw-bolder">Predictive Analytics</span> across multiple wellness dimensions. Because in today's world, student well-being is as important as their grades!     
                </p>
                <button className="btn btn-light b-1">I'm a Student</button>
                <button className="btn btn-light b-2">I'm a Resource center</button>
            </div>
        </div>
        <div className="wellness">
            <div className="wellness-img"><img src={img3}/><p>9 Wellness Dimensions</p></div>
            <div>
            <div className="box deg-0"><h4>Career</h4><p>A  professionally sound (or career well) person engages in work to gain personal satisfaction and enrichment, consistent with values, goals and lifestyle.</p></div>
            <div className="box deg-45"><h4>Emotional</h4><p>An emotionally well person can identify, express and manage the entire range of feelings and would consider seeking assistance to address areas of concern.</p></div>
            <div className="box deg-90"><h4>Financial</h4><p>A financially well person is fully aware of financial state and budgets, saves and manages finances in order to achieve realistic goals.</p></div>
            <div className="box deg-135"><h4>Environmental</h4><p>An environmentally well person recognizes the responsibility to preserve, protect, and improve the environment and appreciates the interconnectedness of nature and the individual.</p></div>
            <div className="box deg-180"><h4>Social</h4><p>A socially well person has a network of support based on interdependence, mutual trust, respect and has developed a sensitivity and awareness towards the feelings of others.</p></div>
            <div className="box deg-225"><h4>Spiritual</h4><p>A spiritually well person seeks harmony and balance by openly exploring the depth of human purpose, meaning, and connection through dialogue and self-reflection.</p></div>
            <div className="box deg-270"><h4>Creative</h4><p>A creatively well person values and actively participates in a diverse range of arts and cultural experiences as a means to understand and appreciate the surrounding world</p></div>
            <div className="box deg-315"><h4>Intellectual</h4><p>An intellectually well person values lifelong learning and seeks to foster critical thinking, develop moral reasoning, expand worldviews, and engage in education for the pursuit of knowledge.</p></div>
            
            </div>
            
        </div>
        <div className="home-testimonials">
            <h4 className="my-4 fw-bold">Testimonials</h4>
            <div className="d-flex my-5 container">
                <div className="testimonials-box">
                    <h5>John</h5>
                    <p className="my-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morb         
                    </p>
                </div>
                <div className="testimonials-box">
                    <h5>John</h5>
                    <p className="my-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morb         
                    </p>
                </div>
                <div className="testimonials-box">
                    <h5>John</h5>
                    <p className="my-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Posuere morb         
                    </p>
                </div>
            </div>
            <div>
                <h4>Share your experience with us and help others discover WellnessU!</h4>
                <form className="d-flex flex-column testimonials-content container ">
                    <div className="sub-content">
                    <label>Name</label>
                    <input className="name-input"/>
                    </div>
                    <div className="sub-content">
                    <label>Email</label>
                    <input className="name-input"/>
                    </div>
                    <div className="sub-content">
                    <label>Review</label>
                    <input className="review-input"/>
                    </div>
                    
                </form>
            </div>
        </div>
        <div></div>
        
        </div>
    )
}