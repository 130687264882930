import React from "react"
import Navbar from "../Navbar/Navbar"
import img7 from "../images/homebg.jpg";
import "../Crc/crcform.css"
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

export default function Crcform(){
    return(
        <div>
            <div><Navbar/></div>
            <div className="">
                <img className="crcform-img" src={img7}/>
                <div className="logindiv3 ">
                    <h4>CRC Affiliation Form</h4>
                    <div className="d-flex">
                        <div className="crcdiv">
                            <p className="text-start fw-medium  my-5 ">Name</p>
                            <p className="text-start fw-medium  my-5 ">Resource Center Name</p>
                            <p className="text-start fw-medium  my-5 ">Email</p>
                            <p className="text-start fw-medium  my-5 ">University</p>
                            <p className="text-start fw-medium  my-5 ">Top one wellness dimensions you address</p>
                            <p className="text-start fw-medium  my-5 ">Extent of students outreach </p>
                            <p className="text-start fw-medium  my-5 ">User ID</p>
                            <p className="text-start fw-medium  my-5 ">Previous Experience with wellness Support Services</p>
                            <p className="text-start fw-medium  my-5 ">Wellness Goals or Expectations</p>
                            <p className="text-start fw-medium  my-5 ">Additional information/ questions </p>
                            <p className="text-start fw-medium  my-5 ">Preferred mode of communication</p>
                            <p className="text-start fw-medium  my-5 ">Would you like to participate in Support Groups or Workshops</p>
                            <p className="text-start fw-medium  my-5 ">How did the you hear about WellnessU?</p>
                        </div>
                        <div className="d-flex flex-column grid22">
                            <input className="form-input my-3"/>
                            <input  className="form-input my-3"/>
                            <input  className="form-input my-3"/>
                            <select  className="form-input my-3"><option></option></select>
                            <select  className="form-input my-3"><option></option></select>
                            <select  className="form-input my-3"><option></option></select>
                            <input  className="form-input my-3"/>
                            <div className="d-flex form-div my-3 "><div>Yes</div><div className="mx-2">No</div></div>
                            <input  className="form-input my-3"/>
                            <input  className="form-input my-3"/>
                            <select  className="form-input my-3"><option></option></select>
                            <div className="d-flex form-div my-3 "><div>Yes</div><div className="mx-2">No</div></div>
                            <select  className="form-input my-3"><option></option></select>
                        </div>
                    </div>
                    <Link to="/crcsumit"><input type="submit" className="btn submit-btn"/></Link>
                </div>
            </div>
            <div className="crc-footer"></div>
        </div>
    )
}