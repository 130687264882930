import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import img6 from "../images/homebg.jpg";
import Footer from "../Footer/Footer";
import  {Link, useNavigate } from "react-router-dom";
import axios from "axios"

export default function Studentlogin(){
    const[email,setEmail]=useState(null);
    const[password,setPassword]=useState(null);
    const[repassword,setRepassword]=useState(null);
    const[err,setErr] = useState(false);

    const[useremail,setUseremail]=useState(null);
    const[userpassword,setUserpassword]=useState(null);
    
    const navigate=useNavigate()
    const sumbitHandler=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        if(password===repassword){
            setErr(false);
        try{
            const config = {
                headers: {
                    "Content-type": "application/json",
                }, 
            };
            const response=await axios.post("http://localhost:8000/api/user/register",{email,password},config)
            localStorage.setItem("userInfo", JSON.stringify(response));
            const data=JSON.parse(localStorage.getItem("userInfo.data"))
            console.log(data)
            
            navigate("/studentverify",{state:email})
        }catch(error){
            console.log(error,"Error occured")
        }
    }
    else{
        setErr(true);
    }

    }
    const loginHandler=async(e)=>{
        e.preventDefault()
        e.stopPropagation()
        if(useremail && userpassword ){
            try {
                const config = {
                    headers: {
                        "Content-type": "application/json",
                    },
                };
                const response = await axios.post("http://localhost:8000/api/user/login", { useremail, userpassword }, config)
                
                navigate("/profile")
            } catch (error) {
                console.log(error, "Error occured")
            } 
        }else{
            console.log("Error occured")
        }
       

    }
    return(
        <div>
            <div><Navbar/></div>
            <div className="login">
            <img src={img6}/>
            <div className="logindiv1 d-flex ">
            
            <div className="m-4 d-flex flex-column">
                        <h4 className="text-start">Already a member?</h4>
                        <p className="text-start fw-medium">Login here</p>
                        <form >
                            <div className="d-flex flex-column"><label className="text-start my-2 fw-medium">Email ID</label>
                            <input type="text" value={useremail} onChange={(e)=>setUseremail(e.target.value)}/>
                            </div>
                            <div className="d-flex flex-column">
                            <label className="text-start my-2 fw-medium">Password</label>
                            <input type="text" value={userpassword} onChange={(e)=>setUserpassword(e.target.value)}/>
                            </div>
                        </form>
                        <a className="text-start my-1">Forget Password/ID</a>
                        <button className="btn my-2 login-button" onClick={(e)=>loginHandler(e)}>Submit</button>
            </div>
            
            <div className="dottedline"></div>
            <div className="m-4 d-flex flex-column">
                        <h4 className="text-start">Not a member yet?</h4>
                        <p className="text-start fw-medium">Register here</p>
                        <form >
                            <div className="d-flex flex-column"><label className="text-start my-2 fw-medium">Email ID</label>
                            <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            </div>
                            <div className="d-flex flex-column">
                            <label className="text-start my-2 fw-medium">Password</label>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="d-flex flex-column">
                            <label className="text-start my-2 fw-medium">Re-enter Password</label>
                            <input type="password" value={repassword} onChange={(e)=>setRepassword(e.target.value)}/>
                            </div>
                        </form>
                        {err && <div className="text-danger">*Please fill all fields correctly</div>}
                        
                        <Link to="/studentverify"><button className="btn my-2 login-button" onClick={(e)=>sumbitHandler(e)}>Register</button></Link>
            </div>
            </div>
            

            </div>
            <div className="login-footer"></div>
        </div>
    )
}