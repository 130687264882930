import React from "react";
import StudentrefNavbar from "../StudentrefNavbar/StudentrefNavbar";
import "./StudentProfile.css"
import img8 from "../images/sos.png"

export default function StudentProfile(){
    return(
        <div>
            <div><StudentrefNavbar/></div>
            <div className="student-profile">
                <div className="student-box d-flex container">
                    <div className="profilediv ">
                        <div><img className="profile-img " src={img8}/></div>
                        <div><button className="btn edit-btn">Edit</button></div>
                        
                    </div>
                    <div className=" vl"></div>
                    <div className=" fs-6 submain fw-bolder">
                    <div className="d-flex flex-row   main">
                        <div><label>Name</label></div>
                        <div className="">Sam</div>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>Email</label></div>
                        <di className="">Sam@uci.com</di>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>University</label></div>
                        <div className="">University of California,Irivine</div>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>Degree</label></div>
                        <div>Economics</div>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>Student Category</label></div>
                        <div>International Student</div>
                        
                    </div>
                    <div className="d-flex flex-row    main">
                        <div><label>Gender</label></div>
                        <div>Female</div>
                        
                    </div>
                    <div className="d-flex flex-row   main">
                        <div><label>Ethnicity</label></div>
                        <div>
                        White American
                        </div>
                        
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}