import React from "react";
import CRCnavbar from "./CRCnavbar";
import "../Crc/CrcDashboard.css"
import Footer from "../Footer/Footer";

export default function CrcDashboard(){
    return(
        <div>
            <div><CRCnavbar/></div>
            <div className="container">
                <div className="rect-crc">DashBoard</div>
            </div>
            <div className="crc-footer"></div>
        </div>
    )
}